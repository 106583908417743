import i18n from 'i18next';
import { initReactI18next, } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import ru from './ru.json';
import uk from './uk.json';
import kz from './kz.json';
import en from './en.json';
import az from './az.json';
import uz from './uz.json';
import da from './da.json';
import es from './es.json';
import pt from './pt.json';
import { AVAILABLE_LANGS } from '@/app-config';

const resources = {
  ru: {
    translation: ru
  },
  uk: {
    translation: uk
  },
  en: {
    translation: en
  },
  kk: {
    translation: kz
  },
  az: {
    translation: az
  },
  uz: {
    translation: uz
  },
  da: {
    translation: da
  },
  es: {
    translation: es
  },
  pt: {
    translation: pt
  },
};


i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'en',
    resources,
    debug: true,
    interpolation: {
      escapeValue: false
    },
    supportedLngs: AVAILABLE_LANGS
  });

export default i18n;
